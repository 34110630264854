<template>
  <div class=" p-1 x_scrolls" :key="$route.fullPath" >
    <div class="p-1" v-for="(item,index) in huayHistoryData" :key="index">
      <div class="" :class="`px-2 py-3 huay-result huay-result-img${(index%4 +1)}`" v-if="item.status == 4">
        <div class="-round-running-wrapper"><strong class="-running-title">ยกเลิกรอบ</strong>
          <strong class="-date">{{ item.title }}</strong>
          <!-- <strong class="-running-title">เวลา 16:00</strong> -->
        </div>
      </div>
      <div class="" :class="`px-2 py-2 huay-result huay-result-img${(index%4 +1)}`" v-else>
        <div class="-round-running-wrapper">
          <strong class="-date">{{ item.title }}</strong>
        </div>
        <div class="-result-wrapper m-0">
          <div class="-result-item-wrapper MuiBox-root css-0"><span class="-title">{{ (lottoType == 1) ? `รางวัลที่ 1` : item.threeDigit.length+` ตัวบน` }}</span><span
              class="-sub-title">{{ item.threeDigit }}</span></div>
          <div class="-result-item-wrapper MuiBox-root css-0" v-if="item.twoDigit!=''">
            <span class="-title">2 ตัวล่าง</span>
            <span class="-sub-title">{{ item.twoDigit }}</span></div>
        </div>
      </div>
    </div>

    <!-- <div class="p-1">
      <div class="huay-result huay-result-img2  px-2 py-2">
        <div class="-round-running-wrapper">
          <strong class="-date">1 เม.ย. 24</strong>
        </div>
        <div class="-result-wrapper m-0">
          <div class="-result-item-wrapper MuiBox-root css-0"><span class="-title">รางวัลที่ 1</span><span
              class="-sub-title">943598</span></div>
          <div class="-result-item-wrapper MuiBox-root css-0"><span class="-title">2 ตัวล่าง</span><span
              class="-sub-title">79</span></div>
        </div>
      </div>
    </div>
    <div class="p-1">
      <div class="huay-result huay-result-img3  px-2 py-2">
        <div class="-round-running-wrapper">
          <strong class="-date">1 เม.ย. 24</strong>
        </div>
        <div class="-result-wrapper m-0">
          <div class="-result-item-wrapper MuiBox-root css-0"><span class="-title">รางวัลที่ 1</span><span
              class="-sub-title">943598</span></div>
          <div class="-result-item-wrapper MuiBox-root css-0"><span class="-title">2 ตัวล่าง</span><span
              class="-sub-title">79</span></div>
        </div>
      </div>
    </div>
    <div class="p-1">
      <div class="huay-result huay-result-img4  px-2 py-2">
        <div class="-round-running-wrapper">
          <strong class="-date">1 เม.ย. 24</strong>
        </div>
        <div class="-result-wrapper m-0">
          <div class="-result-item-wrapper MuiBox-root css-0"><span class="-title">รางวัลที่ 1</span><span
              class="-sub-title">943598</span></div>
          <div class="-result-item-wrapper MuiBox-root css-0"><span class="-title">2 ตัวล่าง</span><span
              class="-sub-title">79</span></div>
        </div>
      </div>
    </div>
    <div class="p-1">
      <div class="huay-result huay-result-img1  px-2 py-2">
        <div class="-round-running-wrapper">
          <strong class="-date">1 เม.ย. 24</strong>
        </div>
        <div class="-result-wrapper m-0">
          <div class="-result-item-wrapper MuiBox-root css-0"><span class="-title">รางวัลที่ 1</span><span
              class="-sub-title">943598</span></div>
          <div class="-result-item-wrapper MuiBox-root css-0"><span class="-title">2 ตัวล่าง</span><span
              class="-sub-title">79</span></div>
        </div>
      </div>
    </div> -->
  </div>
</template>

<script>
import { mapActions } from 'vuex'
export default {
  data () {
    return {
      lottoType: '',
      huayHistoryData: []
    }
  },
  async created () {
    this.lottoType = this.$route.params.lottoType
    if (this.lottoType !== '') {
      this.huayHistoryData = await this.getHuayResultHistory({
        date: {
          lotType: this.lottoType,
          limit: 5
        }
      })
    }
  },
  methods: {
    ...mapActions(['getHuayResultHistory'])
  }
}
</script>

<style></style>
