<template>
  <div class="row m-0 home p-0">
    <div class=" col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 p-0">
      <div class="container p-0">
        <div class=" m-0 ">
          <app-lotto-history :key="$route.fullPath"  />
        </div>
      </div>
    </div>
    <div class="col-12 p-0">
      <app-lotto />
    </div>
  </div>
</template>

<script>
import lottoPage from '@/pages/huayPage/content/lotto-left.vue'
import lottoHistoryPage from '@/pages/huayPage/content/betHistory.vue'
export default {
  components: {
    'app-lotto': lottoPage,
    'app-lotto-history': lottoHistoryPage
  }
}
</script>

<style></style>
