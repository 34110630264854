<template>
  <div class="row">
    <template>
      <div class="col-6 col-sm-6 col-md-6 col-lg-3 p-1" v-for="(item, index) in getSubCatYeekiAviliableList
          .filter((e) => !getIsCloseYeekee(e.timeEnd, 0))
          .reverse()" v-bind:key="item.id">
            <a  type="button" class="w-100" @click="goToUrl(item.timeEnd, item.yeekiRoundID)">
            <div :class="index == 0 ? `bg_huay3` : `huay-list-item2`">
              <div class="row m-0 py-0 ">
                <div class="col-12 p-1">
                  <div class="-item-content-wrapper text-center">
                    <strong :class="index == 0 ? `-text t_color f_08` : `-text2 t_color2 f_08`">{{ item.yeekiRoundNum }}({{ getCloseTime(item.timeEnd) }})</strong>
                    <div class="">
                      <div class="x-countdown-timer-wrapper -timer css-dtlfy1 t_time2">
                        <i class="far fa-clock -ic"></i>
                        <span data-finaldate="1610871600000" class="-time2 pl-1 countdown f_08"
                        v-html="calTime(item.timeEnd)"></span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </a>
        </div>
      </template>
  </div>
</template>
<script>
import { mapActions, mapGetters, mapState } from 'vuex'
import moment from 'moment'
export default {
  async created () {
    this.catalogId = this.$route.params.lottoType
    setInterval(() => {
      this.getNow()
    }, 1000)
    this.setLoading(true)
    if (this.getYeekiCatalogList.length === 0) {
      await this.getYeekiCatalog()
    }
    await this.getYeekiRound(this.catalogId)
    this.yeeki = this.getYeekiCatalogList.find(
      (e) => Number(e.subCatID) === Number(this.catalogId)
    )
    this.setLoading(false)
  },
  methods: {
    ...mapActions(['getYeekiCatalog', 'setLoading', 'getYeekiRound']),
    goToUrl (timeEnd, yeekiRoundID) {
      if (this.isMember) {
        if (this.getIsCloseYeekee(timeEnd, 2)) {
          this.$router.replace(`/member/lottery/yeeki-result/${this.catalogId}/${yeekiRoundID}`)
        } else {
          this.$router.replace(`/member/lottery/yeeki/${this.catalogId}/${yeekiRoundID}`)
        }
      } else {
        this.$bvModal.show('modal-user-login')
      }
    },
    calTime (value) {
      const now = this.currentDate ? this.currentDate : ''
      const final = moment(value).diff(now)
      if (final > 0) {
        const hours = Math.floor(
          (final % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
        )
        let minutes = Math.floor((final % (1000 * 60 * 60)) / (1000 * 60))
        if (minutes < 10) {
          minutes = '0' + minutes
        }
        let seconds = Math.floor((final % (1000 * 60)) / 1000)
        if (seconds < 10) {
          seconds = '0' + seconds
        }
        if (hours > 0) {
          return `${hours} ชั่วโมง`
        }
        return `</small>${minutes}<small>:</small>${seconds}`
      } else {
        return '00<small>:</small>00<small>:</small>00'
      }
    },
    getIsCloseYeekee (currentX, min) {
      const now = this.currentDate !== null ? this.currentDate : ''
      const current = moment(currentX).add(min, 'minutes')
      const final = moment(current).diff(now)
      if (final > 0) {
        return false
      } else {
        return true
      }
    },
    getIsClose (current) {
      const now = this.currentDate !== null ? this.currentDate : ''
      const final = moment(current).diff(now)
      if (final > 0) {
        return false
      } else {
        return true
      }
    },
    getCloseTime (time) {
      return moment(String(time)).format('HH:mm')
    },
    getTime (time) {
      const timer = moment(time)
      const min = timer.minutes().toString()
      const hour = timer.hours().toString()
      const pad = '00'
      const minCal = pad.substring(0, pad.length - min.length) + min
      const hourCal = pad.substring(0, pad.length - hour.length) + hour

      return `${hourCal}: ${minCal}`
    },
    getNow () {
      this.currentDate = moment()
    }
  },
  computed: {
    ...mapGetters(['getYeekiCatalogList', 'getSubCatYeekiAviliableList']),
    ...mapState({
      isMember: (state) => state.authModule.isMember
    })
  },
  data: function () {
    return {
      currentDate: moment(),
      catalogId: '',
      yeeki: {}
    }
  }
}
</script>
