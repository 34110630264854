<template>
  <div class="lottery">
    <div id="section-content" class="container container-fluid">
      <b-overlay id="overlay-background" :show="getLotteryAllList.length == 0" rounded="lg" variant="dark"
        opacity="0.35" blur="2px">
        <div class="container">
          <div class="row m-0">
            <div class="col-4 col-sm-4 col-md-4 col-lg-3 p-1 div_y_scroll">
              <div class="row m-0 y_scroll">
                <div class="col-12 p-1" @click="updateIndex(item)" v-for="(item,index) in dummyList.getYeekiCatalogList" :key="index">
                  <router-link   v-bind:to="`/lotto/`+item.subCatID">
                    <div class="" :class="isPageActive(item.subCatID) ? `bg_huay2` : `huay-list-item2`">
                      <div class="row m-0 py-0 ">
                        <div class="col-4 p-0">
                          <div class="row">
                            <img  :src="require('@/assets/huay/'+item.img)" class="jss86 -img img_left pt-2" alt="Lotmatic แทงหวยออนไลน์">
                            </div>
                          <!-- <span v-bind:class="getIconClass(item.titleLottoCatagory)"></span> -->
                        </div>
                        <div class="col-8 p-1">
                          <div class="-item-content-wrapper text-center">
                            <strong :class="isPageActive(item.subCatID) ? `-text t_color f_09` : `-text2 t_color2 f_08`">{{ item.subCatName }}</strong>
                            <div class="">
                              <div class="x-countdown-timer-wrapper -timer css-dtlfy1 t_time"><i
                                  class="far fa-clock -ic"></i><span class="-time pl-1" v-html="getCountDownRound(item.subCatID, index)"></span></div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </router-link>
                </div>
                <template>
                  <div class="col-12 p-1" @click="updateIndex(item)" v-for="(item, index) in (getLotteryAllList.filter(v => (v.isOpen === '1')).sort(function (a, b) { return isATimeOver(a.lottoCloseTime, b.lottoCloseTime) ? -1 : 1 })) " :key="'C'+index">
                      <router-link  v-bind:to="`/lotto/`+item.lottoType">
                      <div :class="isPageActive(item.lottoType) ? `bg_huay2` : `huay-list-item2`">
                        <div class="row m-0 py-0 ">
                          <div class="col-4 p-0">
                            <img :src="require('@/assets/flags/'+item.lottoImage)" class="jss86 -img img_left pt-2" alt="Lotmatic แทงหวยออนไลน์">
                            <!-- <span v-bind:class="getIconClass(item.titleLottoCatagory)"></span> -->
                          </div>
                          <div class="col-8 p-1">
                            <div class="-item-content-wrapper text-center">
                              <strong :class="isPageActive(item.lottoType) ? `-text t_color f_09` : `-text2 t_color2 f_08`">{{ item.titleLottoCatagory }}</strong>
                              <div class="">
                                <div class="x-countdown-timer-wrapper -timer css-dtlfy1 t_time2">
                                  <i class="far fa-clock -ic"></i>
                                  <span data-finaldate="1610871600000" class="-time2 pl-1 countdown f_08"
                                    v-html="calTime(item.lottoCloseTime,0)"></span>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </router-link>
                  </div>
                </template>
                <template >
                  <div class="col-12 p-1" @click="updateIndex(item)" v-for="(item,index) in getLotteryAllList.filter(v => v.isOpen !== '1')" :key="'D'+index">
                    <router-link  v-bind:to="`/lotto/`+item.lottoType">
                      <div :class="isPageActive(item.lottoType) ? `bg_huay2` : `huay-list-item2`">
                        <div class="">
                          <div class="row m-0 py-0 ">
                            <div class="col-4 p-0">
                              <img :src="require('@/assets/flags/'+item.lottoImage)" class="jss86 -img img_left pt-2" alt="Lotmatic แทงหวยออนไลน์">
                            </div>
                            <div class="col-8 p-1">
                              <div class="-item-content-wrapper text-center">
                                <strong :class="isPageActive(item.lottoType) ? `-text t_color f_09` : `-text2 t_color2 f_08`">{{ item.titleLottoCatagory }}</strong>
                                <div class="">
                                  <div class="x-countdown-timer-wrapper -timer css-dtlfy1 t_time_off f_08">
                                    <span>ปิดรับแทง</span>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </router-link>
                  </div>
                </template>
              </div>
            </div>
            <!-- <a v-b-modal.rule-by-id href="#" class="btn btn-outline-secondary btn-sm mr-1" >กติกา</a> -->
            <div class="col-8 col-sm-8 col-md-8 col-lg-9 p-1">
              <div class="row m-0 p-0" >
                <div class="col-12 p-0 py-1" v-b-modal.rule-by-id>
                  <a type="button" class="w-100" >
                    <div class=" -rules-payout-button" style="background-image: url(&quot;https://app.lotmatic.co/build/web/img/button-bg-rules-payout.png&quot;);">
                      <div class="row m-0 px-2 huay-list-item px-1">
                        <div class="col-4 p-0">
                          <img :src="require('@/assets/huay/yeekee1.webp')" class="jss86 -img" alt="Lotmatic แทงหวยออนไลน์"
                            width="50">
                        </div>
                        <div class="col-8 px-1 rule-huay">
                          <div class="-item-content-wrapper">
                            <div class="-title">{{ this.titleHuay }}</div>
                            <div class="-sub-title2">กติกา / อัตราการจ่าย</div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </a>
                </div>
                <div class="col-12 p-0 py-1" v-for="(item,index) in objHuay" :key="'E'+index" >
                  <a class=" py-3" @click="goToUrl(item.isOpen, item.lottoId)" >
                    <div class="x-bet-round-list-item-open" :class="item.isOpen === '1' ? `x-bet-round-list-item-open` : `x-bet-round-list-item-close`">
                      <div class="-item-inner-wrapper">
                        <div class="x-round-title">
                          <span class="-title">{{ item.titleLottoCatagory }}</span>
                          <span class="-time">{{ item.lottoCloseTime }}</span>
                        </div>
                        <div class="x-bet-round-status">
                          <span :class="item.isOpen === '1' ? `` : `c-black`">{{ item.isOpen === '1' ? `แทงหวย` : `ปิดรับแทง` }}</span>
                        </div>
                      </div>
                    </div>
                  </a>
                </div>
                <div class="col-12" v-if="objHuay.length == 0">
                  <app-lotto :key="$route.fullPath" />
                </div>
                <!-- <div class="col-12 p-0 py-1">
                  <router-link class=" py-3" v-bind:to="`/lotto/2`">
                    <div class="x-bet-round-list-item-close">
                      <div class="-item-inner-wrapper">
                        <div class="x-round-title">
                          <span class="-title">หวยไทย</span>
                          <span class="-time">วันพฤหัสที่ 2 พฤษภาคม 2024 16:00</span>
                        </div>
                        <div class="x-bet-round-status ">
                          <span class="c-black">ปิดรับแทง</span>
                        </div>
                      </div>
                    </div>
                  </router-link>
                </div> -->
              </div>
            </div>
          </div>
        </div>
      </b-overlay>
    </div>
    <app-rule-by-id-modal :advice="adviceHuay" :key="$route.fullPath" />
  </div>
</template>

<script>
import ruleByIdModal from '@/components/modals/RuleByIdModalHtml'
import lottoYeekeePage from '@/pages/huayPage/yeekeeLotteryPage.vue'
import { mapActions, mapGetters, mapState } from 'vuex'
import moment from 'moment'
import { dummyList } from '@/constants'
import {
  getClassIcons,
  getClassBackgroundIcons
} from '@/utils'
export default {
  components: {
    'app-lotto': lottoYeekeePage,
    'app-rule-by-id-modal': ruleByIdModal
  },
  data () {
    return {
      dummyList: dummyList,
      currentDate: '',
      show: true,
      lottoType: '',
      objHuay: [],
      adviceHuay: '',
      titleHuay: '',
      idHuay: 0
    }
  },
  async created () {
    this.setLoading(true)
    if (this.isGamePermission === 2) {

    }
    this.lottoType = this.$route.params.lottoType
    setInterval(() => {
      this.getNow()
    }, 1000)
    if (this.getLottoResultAllList[1][0].length === 0) {
      this.getLottoResultAll('')
    }
    await this.getLottoAll()
    this.objHuay = this.getLotteryAllList.filter(v => (Number(v.lottoType) === Number(this.lottoType)))
    this.updateIndex(this.objHuay[0])
    this.setLoading(false)
  },
  methods: {
    ...mapActions(['getLottery', 'setLoading', 'getLottoAll', 'getYeekiCatalog', 'getLottoResultAll']),
    getIconClass (title) {
      if (title) {
        return getClassIcons(title)
      } else {
        return ''
      }
    },
    getBackgroundColor (title) {
      if (title) {
        const classes = getClassBackgroundIcons(title)
        return classes
      } else {
        return ''
      }
    },
    calTime (value, format) {
      const final = moment(value)
      const now = this.currentDate ? this.currentDate : ''
      const timeleft = final - now
      if (timeleft > 0) {
        const days = Math.floor(timeleft / (1000 * 60 * 60 * 24))
        let hours = Math.floor((timeleft % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60))
        let minutes = Math.floor((timeleft % (1000 * 60 * 60)) / (1000 * 60))
        if (hours < 10) {
          hours = '0' + hours
        }
        if (minutes < 10) {
          minutes = '0' + minutes
        }
        let seconds = Math.floor((timeleft % (1000 * 60)) / 1000)
        if (seconds < 10) {
          seconds = '0' + seconds
        }
        if (format === 1) {
          if (hours > 0) {
            return `${hours} ชั่วโมง`
          }
          return `</small>${minutes}<small>:</small>${seconds}`
        } else {
          if (days > 1000) {
            return ''
          } else if (days > 0) {
            return `${days}<small>วัน</small>`
          } else if (days > 0) {
            return `${days}<small>วัน</small>`
          } else {
            return `${hours}<small>:</small>${minutes}<small>:</small>${seconds}`
          }
        }
      } else {
        return '00<small>:</small>00<small>:</small>00'
      }
    },
    getIsClose (value) {
      const final = moment(value)
      const now = this.currentDate ? this.currentDate : ''
      const timeleft = final - now
      if (timeleft > 0) {
        return false
      } else {
        return true
      }
    },
    getTime (time) {
      const timer = moment(time)
      const min = timer.minutes().toString()
      const hour = timer.hours().toString()
      const pad = '00'
      const minCal = pad.substring(0, pad.length - min.length) + min
      const hourCal = pad.substring(0, pad.length - hour.length) + hour

      return `${hourCal}: ${minCal}`
    },
    getNow () {
      this.currentDate = moment()
    },
    getTimeStamp (time) {
      return moment(time)
    },
    isATimeOver (a, b) {
      return moment(a) < moment(b)
    },
    isPageActive (lotType) {
      return (Number(this.$route.params.lottoType) === Number(lotType))
    },
    goToUrl (isOpen, lottoId) {
      if (this.isMember) {
        this.$router.replace(isOpen !== '1' ? `/member/lottery/lotto/result/${lottoId}` : `/member/lottery/lotto/${lottoId}`)
        // eturn isOpen !== '1' ? `/member/lottery/lotto/result/${lottoId}` : `/member/lottery/lotto/${lottoId}`
      } else {
        this.$bvModal.show('modal-user-login')
        // return '#'
      }
    },
    updateIndex (item) {
      if (item.lottoType > 0) {
        const temp = this.getLotTypeList.filter(v => (v.id === item.lottoType))
        if (temp.length > 0) {
          this.idHuay = temp[0].id
          this.titleHuay = temp[0].title
          this.adviceHuay = temp[0].advice
        }
      }
      if (item.isYeekee === true) {
        this.objHuay = []
      } else {
        this.objHuay = []
        this.objHuay.push(item)
      }
    },
    getIsCloseYeekee (currentX, min) {
      const now = this.currentDate !== null ? this.currentDate : ''
      const current = moment(currentX).add(min, 'minutes')
      const final = moment(current).diff(now)
      if (final > 0) {
        return false
      } else {
        return true
      }
    },
    getCountDownRound (subCatID, index) {
      let listTmp = []
      if (this.getLottoResultAllList[1][index].length > 0) {
        listTmp = this.getLottoResultAllList[1][index].filter((e) => !this.getIsCloseYeekee(e.date_close, 0))
      }
      if (listTmp.length > 0) {
        return this.calTime(listTmp[0].date_close, 1)
      }
      return ''
    }
  },
  computed: {
    ...mapGetters(['getLotteryAllList', 'getYeekiCatalogList', 'getLotTypeList', 'getLottoResultAllList']),
    ...mapState({
      isMember: (state) => state.authModule.isMember,
      isGamePermission: (state) => state.authModule.isGamePermission
    })
  }
}
</script>
